<template>
  <b-card no-body class="m-2">
    <b-card-header class="pb-50 text-capitalize">
      <h5>{{ $t("filter") }}</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="3" md="3" class="mb-md-0 mb-2 text-capitalize">
          <label>Master account</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="masterFilter"
            :options="masterOptions"
            class="w-100"
            multiple
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:masterFilter', val)"
          />
        </b-col>
        <b-col cols="3" md="3" class="mb-md-0 mb-2 text-capitalize">
          <label>{{ $tc("sectors.title", 2) }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="sectorFilter"
            :options="sectorOptions"
            class="w-100"
            multiple
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:sectorFilter', val)"
          />
        </b-col>
        <b-col cols="3" md="3" class="mb-md-0 mb-2 text-capitalize">
          <label>{{ $tc("centers.title") }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="centerFilter"
            :options="centerOptions"
            class="w-100"
            multiple
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:centerFilter', val)"
          />
        </b-col>
        <b-col cols="3" md="3" class="mb-md-0 mb-2 text-capitalize">
          <label>online</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="onlineFilter"
            :options="onlineOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:onlineFilter', val)"
          />
        </b-col>
        <b-col cols="3" md="3" class="mb-md-0 mb-2 text-capitalize">
          <label>{{ $tc("unit.title", 2) }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="unitFilter"
            :options="tenantOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:unitFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol, BButton } from "bootstrap-vue";
import { integer } from "vee-validate/dist/rules";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  props: {
    tenantOptions: {
      type: Array,
      default: null,
    },
    unitFilter: {
      type: Array,
      default: null,
    },
    masterOptions: {
      type: Array,
      default: null,
    },
    masterFilter: {
      type: Array,
      default: null,
    },
    sectorOptions: {
      type: Array,
      default: null,
    },
    sectorFilter: {
      type: Array,
      default: null,
    },
    centerOptions: {
      type: Array,
      default: null,
    },
    centerFilter: {
      type: Array,
      default: null,
    },
    onlineOptions: {
      type: Array,
      default: null,
    },
    onlineFilter: {
      type: Number,
      default: null,
    },
  },
};
</script>
import {ref, computed, watch} from "@vue/composition-api";
// import { title } from '@core/utils/filter'
import {useToast} from "vue-toastification/composition";
import ToastificationContent
  from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment-timezone";
import store from "@/store";
import {useUtils as useI18nUtils} from "@core/libs/i18n";
import { debounce } from 'lodash'

export default function useList() {
  const {t} = useI18nUtils();
  const trans = {
    "messages.error.server_error": t("messages.error.server_error"),
  };
  const refListTable = ref(null);
  const masterFilter = ref(null);
  const sectorFilter = ref(null);
  const centerFilter = ref(null);
  const onlineFilter = ref(null);
  const unitFilter = ref(null);
  const toast = useToast();

  const tableColumns = [
    {label: "actions.name", key: "actions"},
    {label: "density_device.name", key: "description", sortable: true},
    {label: "density_device.email", key: "masterAccount.email", sortable: true},
    {label: "density_device.shot_time", key: "shtime", sortable: true},
    {label: "density_device.type", key: "type", sortable: true},
    {label: "density_device.protocol_version", key: "proto_ver", sortable: true},
    {label: "density_device.firmware_version", key: "fw_ver", sortable: true},
    {label: "density_device.device_version", key: "machine_ver", sortable: true},
    {label: "density_device.usercode", key: "owner_usercode", sortable: true},
    {label: "density_device.connection", key: "connection", sortable: true},
    {label: "density_device.ready", key: "ready", sortable: true},
    {label: "density_device.armed", key: "armed", sortable: true},
    {label: "density_device.tampered", key: "tampered", sortable: true},
    {label: "density_device.low_liquid", key: "low_fluid", sortable: true},
    {label: "density_device.fluid_level", key: "fluid_level", sortable: true},
    {label: "density_device.boil_temp", key: "boil_temp", sortable: true},
    {label: "density_device.shot_number", key: "shot_num", sortable: true},
    {label: "density_device.ac_mains", key: "ac_mains", sortable: true},
    {label: "density_device.diagnostic", key: "diagnostic", sortable: true},
    {label: "density_device.failed_shot", key: "failed_shot", sortable: true},
    {label: "density_device.last_shot", key: "last_shot", sortable: true},
    {label: "density_device.last_check", key: "last_check", sortable: true},

    {
      label: "date.created_at",
      key: "created_at",
      sortable: true,
      formatter: "formatDateAssigned",
    },
    {
      label: "date.updated_at",
      key: "updated_at",
      sortable: true,
      formatter: "formatDateAssigned",
    },
  ];

  const perPage = ref(10);
  const totalList = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    };
  });

  store.dispatch("unit/getAll");
  store.dispatch("sector/getAll");
  store.dispatch("center/getAll");
  store.dispatch("density/getAll");

  const tenantOptions = computed(() => store.getters["unit/getSelectedItems"]);
  const centerOptions = computed(() => store.getters["center/getSelectedItems"]);
  const sectorOptions = computed(() => store.getters["sector/getSelectedItems"]);
  const masterOptions = computed(() => store.getters["density/getSelectedItems"]);

  const onlineOptions = ref([
    {label: "online", value: 1},
    {label: "offline", value: 0},
  ]);

  const fetchList = (ctx, callback) => {
    store
      .dispatch("density_devices/getAll", {
        q: searchQuery.value,
        max: perPage.value,
        "page[n]": currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        id_master_account: masterFilter.value,
        id_sector: sectorFilter.value,
        id_center: centerFilter.value,
        online: onlineFilter.value,
        id_unit: unitFilter.value
      })
      .then((response) => {
        callback(response.data);
        totalList.value = response.meta.total;
      })
      .catch(() => {
        // toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: trans["messages.error.server_error"],
        //     icon: "AlertTriangleIcon",
        //     variant: "danger",
        //   },
        // })
      });
  };

  const formatDateAssigned = (value) => {
    const formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
    return formattedDate;
  };

  const refetchData = () => {
    refListTable.value.refresh();
  };

  watch(
    [
      unitFilter,
      masterFilter,
      onlineFilter,
      centerFilter,
      sectorFilter,
      currentPage,
      perPage,
    ],
    () => {
      refetchData();
    }
  );

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    unitFilter,
    onlineFilter,
    onlineOptions,
    sectorFilter,
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    refetchData,
    tenantOptions,
    centerOptions,
    sectorOptions,
    masterOptions,
    masterFilter,
    centerFilter,
  };
}
